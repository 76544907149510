/*stepper-vertical*/
.c-timeline__item {
    position: relative;
    display: flex;
    gap: 1.5rem;
}

.c-timeline__item:last-child .c-timeline__content:before {
    display: none;
}

.c-timeline__item:first-child .c-timeline__content-active:before {
    display: block;
}

.c-timeline__item:last-child .c-timeline__content-active:before {
    display: none;
}

.c-timeline__content {
    flex: 1;
    position: relative;
    order: 1;
    padding-left: 5.5rem;
    padding-bottom: 1rem;
}

.c-timeline__content:before {
    content: "";
    position: absolute;
    right: 100%;
    top: 0;
    height: 100%;
    width: 4px;
    background-color: #BDDDFF;
}

.c-timeline__content:after {
    content: "";
    position: absolute;
    left: calc(0px - 8px);
    top: 0;
    width: 12px;
    height: 12px;
    background-color: #BDDDFF;
    z-index: 1;
    border-radius: 50%;
}

.c-timeline__content-active {
    flex: 1;
    position: relative;
    order: 1;
    padding-left: 5.5rem;
    padding-bottom: 1rem;
}

.c-timeline__content-active:before {
    content: "";
    position: absolute;
    right: 100%;
    top: 0;
    height: 100%;
    width: 4px;
    background-color: #2B6CB0;
}

.c-timeline__content-active:after {
    content: "";
    position: absolute;
    left: calc(0px - 8px);
    top: 0;
    width: 12px;
    height: 12px;
    background-color: #2B6CB0;
    z-index: 1;
    border-radius: 50%;
}