

.notification-list {
    position: fixed;
    z-index: 1000000;
    right: -220px;
    bottom: 2%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.notification-list-animation {
    position: fixed;
    z-index: 1000000;
    right: 20px;
    bottom: 2%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    transition: 0.3s ease;
}

.portals-notify {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 320px;
    height: auto;
    min-height: 56px;
    margin-bottom: 5px;
    padding: 10px 47px 10px 16px;
    transition: all 0.4s ease;
    border-radius: 12px;
    filter: drop-shadow(0px 14px 28px rgba(0, 0, 0, 0.16)) drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.22));
}

.portal__container {
    display: flex;
    flex-direction: column;
}

.portals-text-error {
    display: flex;
    align-items: center;
}

.portals-text-info {
    display: flex;
    align-items: center;
}

.portals-text-warning {
    display: flex;
    align-items: center;
}

.portals-notify-information {
    display: flex;
    align-items: center;
    background-color: #deeeff;
}

.close-notify {
    position: absolute;
    top: 19px;
    right: 16px;
    cursor: pointer;
}

.close-notify button {
    border: none;
    background-color: inherit;
}

.notify-button {
    color: #1A202C;
    padding: 10px;
    border-radius: 8px;
    background-color: #2B6CB0;
}

.portals-notify-error {
    background: #FEB2B2;
}

.portals-notify-error p {
    color: #1A202C;
}

.portals-notify-success {
    display: flex;
    align-items: center;
    background: #9AE6B4;
}

.portals-text-success {
    display: flex;
    align-items: center;
}

.portals-text-success p {
    color: #1A202C;
}

.portals-notify-info {
    display: flex;
    align-items: center;
    background: #DEEEFF;
}

.portals-notify-warning {
    display: flex;
    align-items: center;
    background: #FBD38D;
}

.text-overflow {
    margin: 0;
    overflow: hidden;
    overflow-wrap: anywhere;
    text-overflow: ellipsis;
}

.button-clear-all {
    height: 40px;
    width: 100%;
    background-color: #718096;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
}

.button-clear-all:hover {
    background-color: #586988;
}

.text-header {
    overflow: hidden;
    overflow-wrap: anywhere;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 260px;
    font-weight: 700;
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.fade-out {
    animation: fadeOut 0.5s forwards !important;
}

@keyframes slideIn {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.slide-in {
    animation: slideIn 0.5s ease-out;
}

@media (min-width: 768px) {
    .portals-notify {
        width: 376px;
    }
}
