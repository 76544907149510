@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: "Trebuchet MS";
        font-weight: 400;
        font-display: swap;
        src: url("font/TrebuchetMS.ttf") format('ttf');
    }

    @font-face {
        font-family: "Trebuchet MS";
        font-weight: 700;
        font-display: swap;
        src: url("font/TrebuchetMS_bold.ttf") format('ttf');
    }

    body {
        font-family: "Trebuchet MS", sans-serif;
        background: #EAEEF1;
    }

    html, body, #root {
        height: 100%;
    }

    /*sidebar*/

    .sidebar {
        position: fixed;
        display: flex;
        z-index: 40;
    }

    .sidebar-container {
        @apply mt-4 flex flex-col gap-2 relative
    }

    .sidebar-link {
        @apply flex items-center text-sm gap-3.5 font-medium px-2 py-2;
    }

    .sidebar-container:last-child .sidebar-link {
        @apply justify-between;
    }

    .unread {
        @apply rounded-[50%] bg-orange w-[16px] h-[16px];
    }

    /*pagination*/

    .pagination-list {
        @apply text-sm text-blue cursor-pointer mr-1.5 font-bold  hover:text-blueHover;
    }

    .pagination-list-active {
        @apply text-sm text-black cursor-auto mr-1.5 font-bold;
    }

    .pagination-left {
        @apply w-[16px] h-[16px] cursor-pointer mr-1.5;
    }

    .pagination-left:hover path {
        @apply fill-blueHover;
    }

    .pagination-disabled-left {
        @apply w-[16px] h-[16px] cursor-not-allowed mr-1.5;
    }

    .pagination-disabled-left path {
        @apply fill-gray stroke-gray;
    }

    .pagination-right {
        @apply w-[16px] h-[16px] cursor-pointer rotate-180 ml-1.5;
    }

    .pagination-right:hover path {
        @apply fill-blueHover;
    }

    .pagination-disabled-right {
        @apply w-[16px] h-[16px] cursor-not-allowed rotate-180 ml-1.5;
    }

    .pagination-disabled-right path {
        @apply fill-gray stroke-gray;
    }

    /*tabs*/

    .active-head-tabs {
        @apply border-b-[4px] border-blueHover pt-1 text-blueHover pb-0;
    }

    .content-tabs {
        display: none;
    }

    .active-tabs {
        display: block;
    }

    .editing-input {
        @apply outline outline-1 outline-[lightgray] rounded-[4px]  px-2;
    }

    /*select*/

    .select-container {
        @apply relative flex justify-between items-center bg-white border-[2px] pr-[12px] w-full hover:border-blue focus:border-blue placeholder-grayLight text-sm;
    }

    .select-items {
        @apply rounded-b-lg bg-white max-h-[200px] overflow-y-auto z-[100] absolute top-[100%] w-full border-r-[2px] border-l-[2px] border-b-[2px] border-blue;
    }

    .select-item {
        @apply py-[8px] text-sm px-[12px] cursor-pointer hover:bg-blue hover:text-white last:rounded-b-[2px];
    }

    /*download*/

    .label-photo {
        @apply px-4 flex w-[144px] m-auto h-[110px] flex-col justify-center items-center cursor-pointer;
    }

    .label-single-photo {
        @apply rounded-lg px-4 flex w-[240px] m-auto h-[150px] flex-col justify-center items-center cursor-pointer
    }

    /*iconStyle*/

    .button-delete-photo:hover .icon-trash path {
        fill: #C72626;
    }

    .close-icon {
        @apply cursor-pointer;
    }

    .icon-cross-hover:hover path {
        stroke: #E53E3E;
    }

    .icon-cross path {
        stroke: #2B6CB0;
    }

    .cross-svg:hover path {
        stroke: #718096;
    }

    .dot-svg:hover path {
        fill: #09427D;
    }

    .filter-svg:hover path {
        fill: #09427D;
    }

    .plus-svg path {
        stroke: #2B6CB0;
    }


    .svg-green:hover path {
        fill: #69C38F;
    }

    /*popover*/
    .popover {
        @apply flex relative items-center justify-center;
    }

    .popover-span-hidden {
        @apply hidden;
    }

    .popover-svg {
        @apply hidden;
    }

    .popover:hover .popover-svg {
        @apply absolute block top-[-11px] z-50;
    }

    .popover:hover .popover-svg path {
        @apply fill-backgroundModal;
    }

    .popover:hover .popover-span-hidden {
        @apply absolute flex justify-center items-center text-nowrap top-[-50px] right-[0] h-[40px] bg-backgroundModal px-3 shadow-[0_4px_8px_0_rgba(0,0,0,0.25)] rounded-lg;
    }

    /*profile*/

    .profile-text:first-child {
        @apply pl-0;
    }

    .profile-text {
        @apply text-base text-black cursor-pointer;
    }

    .profile-text:last-child {
        @apply border-none;
    }

    .profile-active-text {
        @apply text-blue
    }

    .profile-title {
        @apply text-base text-gray mb-5 min-w-[100px];
    }

    .profile-subtitle {
        @apply text-gray text-xs bg-white;
    }

    .profile-container:first-child {
        @apply pl-0;
    }

    .profile-container {
        @apply px-[20px] border-r-[2px] border-backgroundModal;
    }

    .profile-container:last-child {
        @apply pr-0 border-none;
    }

    /*tabs*/

    .tabs-header {
        @apply border-grayLight;
    }

    .tabs-header:last-child {
        @apply border-none mb-2;
    }

    .tabs-border {
        @apply border-r-[1px] border-gray mr-3 pl-3
    }

    .tabs-header:last-child .tabs-border {
        @apply border-none;
    }

    .text-fixed {
        overflow: hidden;
        overflow-wrap: anywhere;
        text-overflow: ellipsis;
    }

    .text-anywhere {
        overflow-wrap: anywhere;
    }

    .pagination-select-text {
        @apply flex justify-center items-center text-sm text-[#4A5568];
    }

    .pagination-select-text:hover svg path {
        fill: #09427D;
    }

    .pagination-select-text:hover span {
        @apply opacity-80;
    }

    /*input*/

    .label-user {
        @apply text-gray h-[2px] flex items-center text-xs absolute top-[0px] left-[-2px] px-[4px];
    }

    .input-label {
        @apply text-gray h-[2px] flex items-center text-xs absolute top-[0px] left-[10px] bg-white px-[4px];
    }

    .input-user {
        @apply text-sm text-black bg-inherit w-full placeholder-black pt-[8px] pb-[8px] border-[2px] border-white;
    }

    .input {
        @apply rounded-lg text-black font-bold placeholder:font-normal outline-0 py-[8px] px-[12px] border-borderGray border-[2px] w-full hover:border-blue focus:border-blue placeholder-gray text-sm
    }

    .input:disabled {
        @apply rounded-lg text-gray outline-0 cursor-not-allowed bg-white py-[8px] px-[12px] border-borderGray border-[2px] w-full placeholder-grayLight text-sm hover:border-borderGray focus:border-borderGray
    }

    .multi-list-container {
        @apply absolute z-[999] flex bg-white border-r-[2px] border-l-[2px] border-b-[2px] border-blue rounded-b-lg w-full
    }

    .multi-search-wrapper {
        @apply absolute top-0 w-full bg-white flex items-center justify-between px-4 gap-2.5 border-b-[1px] border-[#EAEEF1] mb-2
    }

    /*table*/

    .table-container {
        @apply relative border-[1px] border-borderGray rounded-lg bg-white mb-4;
    }

    .table-wrapper {
        @apply flex justify-between items-center px-3 border-b-[2px] border-backgroundModal
    }

    .table-content {
        @apply w-full text-sm text-left text-black
    }

    .table-thead {
        @apply text-sm text-black bg-white border-backgroundModal border-b-[2px]
    }

    .table-tbody tr:hover {
        @apply bg-lightGray;
    }

    .table-tbody-tr {
        @apply border-backgroundModal bg-white border-b-[2px];
    }

    .table-tbody-tr-marking {
        @apply border-backgroundModal bg-white border-b-[2px];
    }

    .table-tbody-tr-marking:last-child {
        @apply border-0;
    }

    .table-tbody-tr-active {
        @apply border-backgroundModal border-b-[2px] bg-lightGray;
    }

    .table-tbody-th {
        @apply px-5 py-4 font-normal text-black;
    }

    .table-tbody-td {
        @apply px-1 py-2;
    }

    .table-tbody-td-marking {
        @apply px-1 py-2;
    }

    .table-tbody-th-marking {
        @apply px-5 py-4 font-normal text-black rounded-bl-lg;
    }

    .table-tbody-td-marking:first-child {
        @apply rounded-bl-lg;
    }

    .table-tbody-td-marking:last-child {
        @apply rounded-br-lg;
    }

    .table-menu {
        @apply absolute z-10 right-[40px] p-2 border-[2px] border-blue bg-white rounded-lg;
    }

    /*navigation*/

    .nav-link {
        @apply group flex items-center text-sm gap-3.5 font-medium px-2 py-2;
    }

    .sidebar-link:hover .icon-sidebar path {
        fill: white;
    }

    .sidebar-link:hover .svg-block .icon-sidebar path {
        fill: white;
    }

    .icon-sidebar-active path {
        fill: white;
    }
}

.input::placeholder {
    font-weight: 400;
}

.input-default::placeholder {
    font-weight: 400;
}

/*notify*/

.bell-blue path {
    fill: #6BB1FA;
}

.bell-green path {
    fill: #69C38F;
}

.bell-yellow path {
    fill: #FBD38D;
}

/*button*/

button {
    @apply duration-200;
}

.button-blue {
    @apply rounded-lg flex items-center text-sm text-white bg-blue h-[40px] px-[20px] py-[6px] mb-[5px] hover:bg-darkBlue
}

.button-link {
    @apply text-blue text-sm hover:text-blueHover;
}

.button-link-span {
    @apply flex items-center h-[40px];
}

.button-link-span:hover span {
    @apply text-blueHover;
}

.button-link-span:hover .hover-blue path {
    fill: #09427D;
}

.button-green {
    @apply flex items-center h-[40px] rounded-lg bg-green text-sm text-white px-4 hover:bg-hoverGreen;
}

.button-delete-photo, .button-delete-photo-single {
    @apply hidden;
}

.button-filter {
    @apply text-xs text-grayDisabled flex items-center pr-4 cursor-pointer hover:text-gray;
}

.button-filter:hover .filter-svg path {
    stroke: #718096;
}

.button-add {
    @apply text-blue text-sm flex items-center font-bold hover:text-blueHover;
}

.button-add:hover .plus-svg path {
    stroke: #09427D;
}

.button-add__disabled {
    @apply text-gray text-sm flex items-center font-bold cursor-not-allowed;
}

.button-add__disabled:hover .plus-svg path {
    stroke: #718096;
}

.button-add__disabled .plus-svg path {
    stroke: #718096;
}

.download__box {
    @apply relative;
}

.download__box .button-delete-photo,
.download__box .button-delete-photo-single {
    @apply absolute w-[25px] h-[25px] flex items-center justify-center top-[-5px] right-[32%] z-[100] cursor-pointer;
}

.button-delete-photo:hover svg path {
    fill: #E53E3E;
}

.download__box .button-delete-photo {
    @apply absolute w-[25px] h-[25px] flex items-center justify-center top-[-5px] right-[32%] z-[100] cursor-pointer;
}

.download__box .button-delete-photo-single {
    @apply absolute w-[25px] h-[25px] flex items-center justify-center top-[4px] right-[38%] z-[100] cursor-pointer;
}

/*drawer*/

.drawer-scroll {
    overflow: inherit !important;
}

:where(.css-dev-only-do-not-override-djtmh8).ant-drawer .ant-drawer-body {
    overflow: inherit !important;
}

/*spinner*/

.lds-ring {
    display: inline-block;
    position: relative;
    width: 60px;
    height: 60px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 44px;
    height: 44px;
    margin: 8px;
    border: 8px solid;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #BDDDFF transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

/*scroll*/

/* полоса прокрутки (скроллбар) */
::-webkit-scrollbar {
    width: 6px;
    height: 4px;
    border-radius: 8px;

    /* высота для горизонтального скролла */
    background-color: transparent;
}

/* ползунок скроллбара */
::-webkit-scrollbar-thumb {
    box-shadow: inset 1px 1px 10px #B1BBCA;
    border-radius: 8px;
    background-color: #B1BBCA;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #1890ff;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.scroll {
    overflow: hidden;
}

:where(.css-dev-only-do-not-override-djtmh8).ant-drawer .ant-drawer-mask {
    background: none;
}

:where(.css-dev-only-do-not-override-djtmh8).ant-picker {
    font-family: "Trebuchet MS", "Arial", sans-serif;
    border-radius: 8px;
}

:where(.css-dev-only-do-not-override-djtmh8).ant-picker .ant-picker-input >input {
    color: #1A202C !important;
    font-family: "Trebuchet MS", "Arial", sans-serif;
    font-weight: 700;
}

.ant-placeholder-color input::placeholder{
    color: #718096 !important;
    font-weight: 400;
}

:where(.css-dev-only-do-not-override-djtmh8).ant-picker-outlined {
    border: 2px solid #CBD5E0;
}

:where(.css-dev-only-do-not-override-djtmh8).ant-picker-outlined:hover {
    border-color: #2B6CB0;
}

:where(.css-dev-only-do-not-override-djtmh8).ant-picker-outlined:focus, :where(.css-dev-only-do-not-override-djtmh8).ant-picker-outlined:focus-within {
    border-color: #2B6CB0;
    box-shadow: none;
}

/*dot-loader*/

.loader {
    width: 6px;
    height: 12px;
    display: block;
    position: relative;
    margin-left: 6px;
    background: #FFF;
    box-shadow: -6px 0 #FFF, 6px 0 #FFF;
    box-sizing: border-box;
    animation: shadowPulse 2s linear infinite;
}

.burger {
    cursor: pointer;
    display: block;
    position: relative;
    border: none;
    background: transparent;
    width: 32px;
    height: 26px;
}
.burger::before,
.burger::after {
    content: '';
    left: 0;
    position: absolute;
    display: block;
    width: 100%;
    height: 4px;
    border-radius: 10px;
    background: #fff;
}
.burger::before {
    top: 0;
    box-shadow: 0 11px 0 #fff;
    transition: box-shadow .3s .15s, top .3s .15s, transform .3s;
}
.burger::after {
    bottom: 0;
    transition: bottom .3s .15s, transform .3s;
}
.burger.active::before {
    top: 11px;
    transform: rotate(45deg);
    box-shadow: 0 6px 0 rgba(0,0,0,0);
    transition: box-shadow .15s, top .3s, transform .3s .15s;
}
.burger.active::after {
    bottom: 11px;
    transform: rotate(-45deg);
    transition: bottom .3s, transform .3s .15s;
}

.link {
    position: relative;
    text-decoration: none;
    padding-bottom: 5px;
}

.link::after {
    position: absolute;
    content: '';
    left: 50%;
    bottom: 2px;
    display: block;
    width: 0;
    height: 2px;
    background: #2B6CB0;
    transition: 0.3s;
}
.link:hover:after {
    width: 100%;
    left: 0;
}

input::placeholder {
    font-weight: normal;
}

@keyframes shadowPulse {
    33% {
        background: #FFF;
        box-shadow: -6px 0 #09427D, 6px 0 #FFF;
    }
    66% {
        background: #09427D;
        box-shadow: -6px 0 #FFF, 6px 0 #FFF;
    }
    100% {
        background: #FFF;
        box-shadow: -6px 0 #FFF, 6px 0 #09427D;
    }
}